<template>
    <div class="">
        <img :src="require('../public/img/404Graphic.png')" alt="" class="w-75 flex items-center center">
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style lang="scss" scoped>

</style>