var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flipCard",on:{"click":function($event){_vm.isFlipped = !_vm.isFlipped}}},[_c('div',{staticClass:"card",class:{ flipped: _vm.isFlipped }},[_c('div',{staticClass:"side front",staticStyle:{"overflow":"hidden","z-index":"0"}},[_c('div',{staticClass:"img-front absolute",staticStyle:{"height":"35%","width":"100%","object-fit":"cover","border-radius":"5px 5px 0px 0px","background-size":"150%","z-index":"-1"},style:(("background-image: url(" + (require('../../public/img/GH-Watercolor-small.png')) + "); background-position: " + (this.positionShift) + "% " + (((Math.cos(_vm.phase) + 1) / 2) * 100) + "%;\n         filter: hue-rotate(" + _vm.textureHueShift + "deg) saturate(" + _vm.textureSaturationShift + "deg)")),attrs:{"alt":"watercolor card background image"}}),(_vm.image)?_c('div',{staticClass:"people-img z-1",staticStyle:{"margin-top":"47px"}},[_c('img',{style:(_vm.usesPlaceholder ? 'width: 80%' : 'width:100%'),attrs:{"src":_vm.image,"loading":"lazy"}})]):_vm._e(),(
          _vm.person.custom_fields.first_name &&
          _vm.person.custom_fields.first_name[0] &&
          _vm.person.custom_fields.last_name &&
          _vm.person.custom_fields.last_name[0]
        )?_c('h4',{staticClass:"people-name",staticStyle:{"width":"80%","text-overflow":"wrap","margin":"0 auto","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.person.custom_fields.first_name[0] + " " + _vm.person.custom_fields.last_name[0])+" ")]):_vm._e()]),_c('div',{staticClass:"side back gs-card-flex",staticStyle:{"text-overflow":"ellipsis"},attrs:{"alt":""}},[(_vm.person.custom_fields.about && _vm.person.custom_fields.about[0])?_c('p',{staticClass:"people-desc",staticStyle:{"overflow":"hidden"},domProps:{"innerHTML":_vm._s(_vm.person.custom_fields.about[0])}}):_vm._e(),_c('div',{staticClass:"social-media"},[(_vm.person.custom_fields.email && _vm.person.custom_fields.email[0])?_c('div',[_c('a',{staticClass:"icons",attrs:{"href":'mailto:' + _vm.person.custom_fields.email[0]}},[_c('i',{staticClass:"fa fa-envelope"})])]):_vm._e(),(_vm.person.custom_fields.site && _vm.person.custom_fields.site[0])?_c('div',[_c('a',{staticClass:"icons",attrs:{"href":_vm.person.custom_fields.site[0]}},[_c('i',{staticClass:"fa fa-globe"})])]):_vm._e(),(
            _vm.person.custom_fields.instagram &&
            _vm.person.custom_fields.instagram[0]
          )?_c('div',[_c('a',{staticClass:"icons",attrs:{"href":_vm.person.custom_fields.instagram[0]}},[_c('i',{staticClass:"fa fa-instagram"})])]):_vm._e(),(
            _vm.person.custom_fields.linkedin && _vm.person.custom_fields.linkedin[0]
          )?_c('div',[_c('a',{staticClass:"icons",attrs:{"href":_vm.person.custom_fields.linkedin[0]}},[_c('i',{staticClass:"fa fa-linkedin"})])]):_vm._e(),(
            _vm.person.custom_fields.twitter && _vm.person.custom_fields.twitter[0]
          )?_c('div',[_c('a',{staticClass:"icons",attrs:{"href":_vm.person.custom_fields.twitter[0]}},[_c('i',{staticClass:"fa fa-twitter"})])]):_vm._e()]),_c('img',{staticClass:"person-card-watercolor-back",staticStyle:{"height":"15%","width":"100%","padding-top":"300px","object-fit":"cover"},attrs:{"src":"img/GH-Watercolor-small.png","alt":"watercolor card background image","loading":"lazy"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }